@import url(https://fonts.googleapis.com/css?family=Raleway:400,500,800);

/* body {
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: "Raleway", Arial, sans-serif;
  background: #7f9ead;
} */

.cardes {
  position: relative;
  width: 450px;
  height: 450px;
  border-radius: 10px;
  box-shadow: 0px 5px 20px rgba(0, 0, 0, 0.5);
  transition: 0.3s;
  padding: 35px 30px;
  background: #fff;
  cursor: pointer;
  /* margin-right: 30px; */
 
}

.cardes:hover {
  height: 450px;
}

.imgboxes {
  position: relative;
  width: 100%;
  height: auto;
  transform: translateY(-80px);
  z-index: 99;
}



.contentes {
  /* padding: 40px 7px; */
  margin-top: 97px;
  text-align: center;
  transform: translateY(-450px);
  opacity: 0;
  transition: 0.3s;
}

.cardes:hover > .contentes {
  opacity: 1;
  transform: translateY(-180px);
}

.contentes h3 {
  color: #7f9ead;
}

.cont {
  padding: 8px 30px;
  text-align: center;
  /* transform: translateY(-450px);
  opacity: 0;
  transition: 0.3s; */
  color: #7f9ead;
}



/* --------------------------- */
/* // Small devices (landscape phones, 576px and up) */
@media (min-width:300px) and (max-width: 576px) { 
  .cardes {
    position: relative;
    width: 450px;
    margin-top: 43px;
    height: 350px;
    border-radius: 10px;
    box-shadow: 0px 5px 20px rgba(0, 0, 0, 0.5);
    transition: 0.3s;
    padding: 35px 30px;
    background: #fff;
    cursor: pointer;
    /* margin-right: 30px; */
   
  }
  .cont {
    font-size: 20px!important;
    padding: 6px 0px 0px 0px;
    text-align: center;
    color: #7f9ead;
  }
    
}
@media (min-width:360px) and (max-width: 576px) { 
  .cardes {
    position: relative;
    width: 450px;
    margin-top: 43px;
    height: 350px;
    border-radius: 10px;
    box-shadow: 0px 5px 20px rgba(0, 0, 0, 0.5);
    transition: 0.3s;
    padding: 35px 30px;
    background: #fff;
    cursor: pointer;
    /* margin-right: 30px; */
   
  }
  

.cont {
  font-size: 20px!important;
  padding: 6px 0px 0px 0px;
  text-align: center;
  color: #7f9ead;
}
  
  .cardes:hover {
    height: 350px;
    transition: none!important;
  }
  .imgboxes {
    position: relative;
    width: 100%;
    height: 66%;
    top: -19px !important;
    transform: translateY(-80px);
    z-index: 99;
}
.cardes:hover {
  height: 431px;
  margin-top: 38px;
  transition: none!important;
}
.contentes {
  /* padding: 40px 7px; */
  margin-top: 94px!important;
  text-align: center;
  transform: translateY(-450px);
  opacity: 0;
  transition: 0.3s;
}
 }

/* // Medium devices (tablets, 768px and up) */
@media (min-width: 768px) { 
  .cardes {
    position: relative;
    width: 450px;
    margin-top: 43px;
  }
 }

/* // Large devices (desktops, 992px and up) */
@media (min-width: 992px) { 

 }








