
*{
 
        font-family: "Times New Roman", Times, serif;
      
}
.body{
     margin: 0;
    font-family: var(--bs-font-sans-serif);
    font-size: 1rem;
    font-weight: 400;
  
    line-height: 1.5;
    color: #212529;
    background-color: #fff;
    -webkit-text-size-adjust: 100%;
    -webkit-tap-highlight-color: transparent;
}    



/* // Small devices (landscape phones, 576px and up) */
@media (min-width: 300px )  and (max-width: 576px ) { 
        h1{
           font-size:21px!important;
       };
       p{
        font-size: 4px!important;
       
       };
       .h4{
       font-size: calc(1.0rem + .3vw)!important;      
       };
       h6{
        font-size:15px!important;        
       }
       .card1{
        width: auto!important;
        height: auto!important;
      }
   
        }
       
@media (min-width: 360px )  and (max-width: 576px ) { 
        .card1 {
                width: auto!important;
                height: auto!important;
              }
 h1{
    font-size:20px!important;
};
p{
 font-size: 4px!important;

};
.h4{
font-size: calc(1.0rem + .3vw)!important;      
};
h6{
 font-size:15px !important;        
}


/* .fw-bold {
        font-weight: 300!important;
        font-size: 21px!important;
    } */

 }

/* // Medium devices (tablets, 768px and up) */
@media (min-width: 576px) and (max-width: 768px) { 
        h1{
            font-size:25px !important;
            };
            p{
             font-size: 6px !important;
            };
            h4{
             font-size:12px !important;      
            };
            h6{
             font-size:15px !important;        
            }
            .card1 {
                width: auto!important;
                height: auto!important;
              }
}

/* // Large devices (desktops, 992px and up) */
@media (min-width: 768px) and (max-width: 992px) { 
        h1{
                font-size:25px !important;
            };
            p{
             font-size: 6px !important;
            };
            h4{
             font-size:12px !important;      
            };
            h6{
             font-size:15px !important;        
            }
            .card1 {
                width: auto!important;
                height: auto!important
              }
 }

/* // X-Large devices (large desktops, 1200px and up) */
@media (min-width: 1200px) { 
       .card1 {
                width: 475px!important;
                height: 270px!important;
              };

 }

/* // XX-Large devices (larger desktops, 1400px and up) */
@media (min-width: 1400px) { 
        .card1 {
                width: 475px!important;
                height: 270px!important;
              };

 }



   

