.body{
     overflow-y: hidden;
    overflow-x: hidden;
}






/* // Small devices (landscape phones, 576px and up) */
@media (min-width: 576px )  and (max-width: 576px ) { 
    h1{
        font-size: 20px !important;
    };
    p{
        font-size: 16px !important;
       };
       h4{
        font-size:20px !important;      
       };
       h6{
        font-size:15px !important;        
       }
     }
    
    /* // Medium devices (tablets, 768px and up) */
    @media (min-width: 768px) { 
    
    }
    
    /* // Large devices (desktops, 992px and up) */
    @media (min-width: 992px) { 
    
     }
    
    /* // X-Large devices (large desktops, 1200px and up) */
    @media (min-width: 1200px) { 
    
     }
    
    /* // XX-Large devices (larger desktops, 1400px and up) */
    @media (min-width: 1400px) { 
    
     }