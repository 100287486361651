.border1 {
   
    border: 2px solid #dee2e6!important;;
    padding: 5px;
}
.font{
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
}
.rowFont{
  font-weight: bold;
  font-size: 1.1rem;
}

.px-3 {
    padding-right: 1rem!important;
    padding-left: 1rem!important;
}

.py-md-3 {
    padding-top: 1rem!important;
    padding-bottom: 1rem!important;
}
.bg-brand {
    background-color: #003e96;
    color: #fff;
}